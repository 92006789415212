/**
 * Functionality specific to main landing page.
 * Invoked on page by <script> function call:
 *     <script>$(function() { window.elgin.landing() })</script>
 * @function on @global
*/

window.elgin.landing = function() {

	/**
	 * Applies focus to panel based on selected/entered tab
	 * @accessibility
	*/

	$('.nav-tabs a').on('enter click', function() {
		const xPos = window.scrollX
		const yPos = window.scrollY
		setTimeout(() => {
			$($(this).attr('href')).focus()
			window.scrollTo(xPos, yPos)
		}, 200)
	})

}
