/* eslint-disable newline-per-chained-call */

/**
 * Functionality specific to loading and filtering stories.
 * This is invoked by both `window.elgin.inthenews` and
 * `window.elgin.inthenews` function calls.
 * @function on @global
 * @param {jQuery element} $loadMore - jQuery button for loading more stories
 * @param {options} cache - options unique to pages making story load requests
*/

window.elgin.storiesloader = function($loadMore, options) {

	/**
	 * Invokes load of JSON templates on `#loadMore` click
	 * @jquery
	*/

	$loadMore.on('click', (/* event */) => {
		options.replace = false
		options.filter = false
		options.filterByDate = false
		options.cache = true
		window.elgin.loadTemplates(options, focusStories)
		return false
	})

	/**
	 * Invokes load of JSON templates on `.filter-pills` click
	 * @jquery
	*/

	$('.filter-pills.categories a').on('click', function(event) {
		options.filterByDate = false
		loadStories(options, event)
		$('.filter-pills li').removeClass('on')
		$(this).blur().closest('li').addClass('on')
		return false
	})

	$('.filter-pills.dates a').on('click', function(event) {
		options.filterByDate = true
		loadStories(options, event)
		$('.filter-pills li').removeClass('on')
		$(this).blur().closest('li').addClass('on')
		return false
	})

	/**
	 * Helper for loading stories
	 * @function
	 * @param {options} object - same options passed to function
	 * @param {event object} event - event response object
	*/

	function loadStories(options, event) {
		options.replace = true
		options.paginationIndex = 0
		options.filter = { tags: event.target.textContent }
		options.cache = false
		window.elgin.loadTemplates(options, focusStories)
	}

	function focusStories($html, options) {
		if ($html.length) {
			$html
				.first()
				.find('a')
				.first()
				.focus()
			$('html, body').animate({ scrollTop: $html.offset().top - 125 }, 350)
		}
		options.paginationIndex = options.paginationIndex + options.max
		if (options.paginationIndex >= options.$data.length) {
			$loadMore.hide()
		} else {
			$loadMore.show()
		}
	}

}
