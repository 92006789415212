/**
 * `loadTemplates` functionality that loads jQuery templates from `$.templates` (js file jsrender-0.9.89.min.js)
 * @function on @global
 * @param {object} options - options are parameters on {object} for templating:
 *     @param {string} template - script selector ID to draw from
 *     @param {string} url - `url` of JSON feed
 *     @param {string} target - `#selector` target to append content
 *     @param {boolean} replace - default `true` - OPTIONAL whether to replace previous content with new
 *     @param {number} paginationIndex - default `0` - OPTIONAL index to pull from JSON data
 *     @param {number} max - default `8` - OPTIONAL max items to load
 *     @param {boolean} cache - OPTIONAL whether to cache JSON call
 *     @param {object} filter - OPTIONAL if present, filters by object property(ies)
 *     @param {boolean} filterByDate - OPTIONAL if present, also filters by `timestamp` year.
 *         NOTE: Needs @param filter property as year `{ timestamp: '2017' }` to work.
 *     @param {array|object} $data: - results stored in `$data`
 *         NOTE: the $data is always used, but will be refreshed if `options.cache` is false
 * @param {function} callback - native BootStrap carousel DOM element
*/

window.elgin.loadTemplates = (options, callback) => {

	// options = options || {}
	options.replace = options.replace === false ? false : true
	options.paginationIndex = options.paginationIndex || 0
	options.max = options.max || 8

	if (options.cache && options.$data) {
		render(options, callback)
	} else {
		$.ajax({
			dataType: 'json',
			url: options.url,
			success: data => {
				options.$data = data
				render(options, callback)
			},
		})
	}

}

function render(options, callback) {
	const $target = $(options.target)
	const $template = $.templates(options.template)

	if (options.filter) {
		let allFilteredData = []
		let filteredData
		if (options.filterByDate) {
			allFilteredData = options.$data.filter(item => {
				return String((new Date(item.timestamp)).getFullYear()) === options.filter.tags
			})
		} else {
			const keys = Object.keys(options.filter)
			for (let key in keys) {
				filteredData = options.$data.filter(item => {
					return item[keys[key]].indexOf(options.filter[keys[key]]) > -1
				})
				allFilteredData = allFilteredData.concat(filteredData)
			}
		}
		options.$data = allFilteredData
	}

	const $html = $($template.render(options.$data.slice(options.paginationIndex, options.paginationIndex + options.max)))
	if (options.replace) {
		$target.empty()
	}
	if (typeof options.beforeAppend === 'function') {
		options.beforeAppend($html).then(() => {
			$target.append($html)
			if (typeof callback === 'function') {
				callback($html, options)
			}
		})
	} else {
		$target.append($html)
		if (typeof callback === 'function') {
			callback($html, options)
		}
	}

}
