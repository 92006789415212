/**
 * Functionality specific to homepage.
 * Invoked on homepage by <script> function call:
 *     <script>$(function() { window.elgin.mainhome() })</script>
 * @function on @global
*/

window.elgin.mainhome = function() {

	/**
	 * Accessibility: play/pause video on click
	 * @jquery
	*/

	const $videoContainer = $('.container-video')
	const video = document.querySelector('.container-video video')
	$videoContainer.on('mousedown keydown', function(event) {
		if (event.type === 'keydown' && event.keyCode !== 13) {
			return
		}
		if (video.paused) {
			video.play()
		} else {
			video.pause()
		}
	})

	/**
	 * Accessibility: play/pause carousel on focus
	 * @jquery
	*/

	$('body').on('focus click keydown', '*', function(event) {
		if (event.target === this) {
			window.elgin.pauseCarouselOnEvent(event, this, '#home-carousel')
		}
	})

	/**
	 * Functionality for the `choose a path` dropdowns.
	 * This uses jQuery templates and the `sample-choose-a-path.json` file
	 * to populate results in each selector.
	*/

	const $choosePath = $('#choose-a-path .path-dropdowns')
	const $chooseSelectorsTemplate = $.templates('#choose-selectors-template')
	$.ajax({
		dataType: 'json',
		url: '/json/sample-choose-a-path.json',
		success: data => {
			// bind data to all the dropdowns
			const $html = $($chooseSelectorsTemplate.render(data.slice()))
			$html[0].attachedData = data[0]
			$choosePath.append($html)
		},
	})

	/**
	 * Functionality for random images
	 * This uses jQuery templates and the `sample-random-images.json` file
	 * to populate results in each img.
	*/

	const $infographicContainer = $('#infographic-container')
	const $infographicsTemplate = $.templates('#random-images-template')
	$.ajax({
		dataType: 'json',
		url: '/json/sample-random-images.json',
		success: data => {
			// randomly select an image
			const $html = $($infographicsTemplate.render(data[Math.floor(Math.random() * data.length)]))
			$infographicContainer.empty().append($html)
		},
	})

}
