/**
 * Functionality specific to "articles lander" page.
 * Invoked on page by <script> function call:
 *     <script>$(function() { window.elgin.article() })</script>
 * @function on @global
*/

window.elgin.article = function() {

	/**
	 * Accessibility for any carousels on an article page
	 * (see `window.elgin.pauseCarouselOnEvent` function)
	 * @jquery
	*/

	$('.full-article').on('focus click', '*', function(event) {
		if (event.target === this) {
			window.elgin.pauseCarouselOnEvent(event, this, '.article-carousel')
		}
	})

}
