/* eslint-disable newline-per-chained-call */

/**
 * Functionality specific to "in the news" page.
 * Invoked on page by <script> function call:
 *     <script>$(function() { window.elgin.inthenews() })</script>
 * @function on @global
*/

window.elgin.inthenews = function() {

	window.elgin.storiesloader($('#loadMore'), {
		template: '#story-template',
		url: '/json/sample-news.json',
		target: '#main-stories',
		paginationIndex: 0,
	})

}
