/**
 * Functionality specific to news lander/home.
 * Invoked on page by <script> function call:
 *     <script>$(function() { window.elgin.newshome() })</script>
 * @function on @global
*/

window.elgin.newshome = function() {

	/**
	 * Invokes load of JSON templates on `.story-filters` click
	 * @jquery
	*/

	$('.featured-stories .story-filters a').on('click', event => {
		window.elgin.loadTemplates({
			template: '#featured-story-template',
			url: '/json/sample-featured-news.json',
			target: '#featured-stories-container',
			max: 6,
			filter: { tags: event.target.textContent },
		})
		return false
	})

	/**
	 * Invokes load of JSON templates on `.story-filters` click
	 * @jquery
	*/

	$('#in-the-news .story-filters a:not(.more)').on('click', event => {
		window.elgin.loadTemplates({
			template: '#story-template',
			url: '/json/sample-news.json',
			target: '#stories-container',
			max: 3,
			filter: { tags: event.target.textContent },
		})
		return false
	})

}
