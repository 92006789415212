/**
 * Global `elgin` object for sharing functionality accross pages.
 * This "best practice" allows minification of JS files into a single file,
 * without needing to break them up for different pages.
 * Functions are namespaced according to pages, and called from those pages.
 * For example, on the homepage, we instantiate namespaced JS functionality at
 * the bottom of the page in a <script> tag with the following call:
 * 
 *     <script>$(function() { window.elgin.mainhome() })</script>
 * 
 * Etc.
*/

window.elgin = {}

/**
 * Accessibility functionality for stopping carousels on focus or click
 * @function
 * @param {event} event - focus/click event
 * @param {element} element - native BootStrap carousel DOM element
 * @param {string} selector - namespaced selector identifying carousel
*/

const pausedCarousels = {}
window.elgin.pauseCarouselOnEvent = function(event, element, selector, container) {
	container = container || selector
	pausedCarousels[container] = pausedCarousels[container] || {}
	if (event.type === 'click' || (event.type === 'keydown' && event.keyCode === 13)) {
		if ($(element).closest(selector).length) {
			pausedCarousels[container].permaPaused = true
			$(selector).carousel('pause')
		}
	} else if (!pausedCarousels[container].permaPaused) {
		if ($(element).closest(selector).length) {
			pausedCarousels[container].paused = true
			$(selector).carousel('pause')
		} else if (pausedCarousels[container].paused) {
			pausedCarousels[container].paused = false
			$(selector).carousel('cycle')
		}
	}
}

$(function() {

	/**
	 * Accessibility technique for focusing on resources and mega menus
	 * @jquery
	*/

	$('.utility-menu .dropdown-toggle').on('click enter', function() {
		const $target = $(this)
		if ($target.hasClass('active')) {
			$target.attr('aria-expanded', 'true')
		} else {
			$target.attr('aria-expanded', 'false')
		}
	})
	$('.navbar-nav.main').on('keyup', function(event) {
		if (event.keyCode === 9) {
			if (event.target.className.indexOf('dropdown-toggle') > -1) {
				const $toggle = $(event.target)
				const $dropdown = $toggle.closest('.dropdown')
				if (!$dropdown.hasClass('open')) {
					$dropdown.siblings('.open').mouseout()
					$toggle.mouseover()
				}
			}
		}
	})
	$('body').on('focus', '*', function(event) {
		if (!$(event.target).closest('.navbar-nav.main .mega-dropdown').length) {
			$('.navbar-nav.main .mega-dropdown.open').mouseout()
		}
	})


	/**
	 * Technique necessary to add `.active` class to carousels
	 * based on T4 needs
	 * @jquery
	*/

	$('.carousel').each(function(index, carousel) {
		const $carousel = $(carousel)
		$carousel.find('.item').first().addClass('active')
		$carousel.find('.carousel-indicators li').first().addClass('active')
	})

})
