/* eslint-disable newline-per-chained-call */

/**
 * Functionality specific to "featured stories" page.
 * Invoked on page by <script> function call:
 *     <script>$(function() { window.elgin.featuredstories() })</script>
 * @function on @global
*/

window.elgin.featuredstories = function() {

	window.elgin.storiesloader($('#loadMore'), {
		template: '#featured-story-template',
		url: '/json/sample-featured-news.json',
		target: '#featured-stories-container',
		paginationIndex: 0,
		max: 10,
		beforeAppend: ($html) => {
			return new Promise((resolve/* , reject */) => {
				$html.filter('.featured-story').each((index, element) => {
					$(element).removeClass('col-sm-4').addClass('col-sm-6')
				})
				resolve()
			})
		},
	})

}
